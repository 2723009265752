import { useQuery, QueryKey, UseQueryOptions } from "react-query";
import { useHttpClient } from "@laingorourke/core-web-httpclient";
import { httpClientKey } from "../helpers";

export const useApiQuery = <T>(key: QueryKey, path: string, config?: UseQueryOptions<T | null>) => {

    const client = useHttpClient(httpClientKey).client;

    return useQuery(key, async () => (await client.get<T>(path)).data, config);
}
