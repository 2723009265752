import { Button, ColumnDefinition, dateFilter, Page, selectFilter, Table, ToastMessageType, useToasts } from "@laingorourke/core-web-components";

import { faTrashAlt } from "@fortawesome/free-regular-svg-icons/faTrashAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import format from "date-fns/format";
import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { getConfiguration, pluralise } from "../../helpers";
import { useUserHasPermission } from "../../helpers/hooks";
import userPermissions from "../../helpers/permissions";
import { ServiceBusDeadLetter } from "../../models";
import Navigation from "./Navigation";
import { useClearServiceBusDeadLetters, useDeleteServiceBusDeadLetter, useDeleteServiceBusDeadLetters, useResubmitServiceBusDeadLetters, useServiceBusDeadLetters } from "../../services/deadletters";
import { ServiceBusDeadLetterDetails } from "./ServiceBusDeadLetterDetails";

export const ServiceBus: React.FC = () => {

    const seqEndpoint = getConfiguration().seqEndpoint;

    const [showDetails, setShowDetails] = useState(false);
    const [selectedDeadLetter, setSelectedDeadLetter] = useState<ServiceBusDeadLetter>();
    const [selectedDeadLetters, setSelectedDeadLetters] = useState<ServiceBusDeadLetter[]>([]);

    const { data, isLoading } = useServiceBusDeadLetters();
    const resubmitServiceBusDeadLetters = useResubmitServiceBusDeadLetters();
    const deleteServiceBusDeadLetter = useDeleteServiceBusDeadLetter();
    const deleteServiceBusDeadLetters = useDeleteServiceBusDeadLetters();
    const clearServiceBusDeadLetters = useClearServiceBusDeadLetters();

    const permissionCheck = useUserHasPermission();
    const toasts = useToasts();

    const deleteMessage = (deadLetter: ServiceBusDeadLetter) => {
        if (window.confirm("Are you sure you want to delete this dead letter? ")) {
            deleteServiceBusDeadLetter.mutate(deadLetter);
        }
    }

    const resubmitSelectedMessages = () => {
        resubmitServiceBusDeadLetters.mutate([null, { deadLetters: selectedDeadLetters.map(s => ({ topicOrQueue: s.topicOrQueue, id: s.id }))}], {
            onSuccess(response) {
                if (response.status === 207)
                {
                    toasts.newMessage({ title: "Warning", body: `Some dead letters have been successfully submitted, but others failed.`, type: ToastMessageType.Warning });
                    return;
                }
                toasts.newMessage({ title: "Success", body: `The dead ${pluralise("letter", selectedDeadLetters)} ${pluralise("has", selectedDeadLetters)} been resubmitted and deleted.`, type: ToastMessageType.Success });
            },
            onError() {
                toasts.newMessage({ title: "Error", body: `There was an error resubmitting the dead ${pluralise("letter", selectedDeadLetters)}.`, type: ToastMessageType.Error });
            }
        });
    }

    const deleteSelectedMessages = () => {
        if (window.confirm(`Are you sure you want to delete ${pluralise("this", selectedDeadLetters)} dead ${pluralise("letter", selectedDeadLetters)}?`)) {
            deleteServiceBusDeadLetters.mutate(selectedDeadLetters.map(s => ({ topicOrQueue: s.topicOrQueue, id: s.id })), {
                onSuccess(response) {
                    if (response.status === 207) {
                        toasts.newMessage({ title: "Warning", body: `Some dead letters have been marked for deletion, but others failed.`, type: ToastMessageType.Warning });
                        return;
                    }
                    toasts.newMessage({ title: "Success", body: `The dead ${pluralise("letter", selectedDeadLetters)} ${pluralise("has", selectedDeadLetters)} been marked for deletion. This may not happen immediately.`, type: ToastMessageType.Success });
                },
                onError() {
                    toasts.newMessage({ title: "Error", body: `There was an error deleting the dead ${pluralise("letter", selectedDeadLetters)}.`, type: ToastMessageType.Error });
                }
            });
        }
    }

    const deleteAllMessages = () => {
        if (window.confirm("Are you sure you want to delete ALL dead letters? This is irreversible!")) {
            clearServiceBusDeadLetters.mutate(null);
        }
    }

    const columns: ColumnDefinition<ServiceBusDeadLetter>[] = useMemo(() => [
        {
            text: "Topic or Queue",
            field: row => row.topicOrQueue,
            sortable: true,
            headerClass: "column-20",
            filter: selectFilter({
                multi: true,
                options: data?.map(d => d.topicOrQueue).reduce((arr: any[], current) => {
                    if (!arr.some(d => d.value === current)) {
                        arr.push({ value: current, label: current });
                    }
                    return arr;
                }, []) ?? [],
            })
        },
        {
            text: "Enqueued Time",
            field: row => format(new Date(row.enqueuedTime), "yyyy-MM-dd HH:mm:ss"),
            sortable: true,
            headerClass: "column-15",
            filter: dateFilter()
        },
        {
            text: "Application ID",
            field: row => row.applicationId,
            sortable: true,
            headerClass: "column-25",
            filter: selectFilter({
                multi: true,
                options: data?.map(d => d.applicationId).reduce((arr: any[], current) => {
                    if (current && !arr.some(d => d.value === current)) {
                        arr.push({ value: current, label: current });
                    }
                    return arr;
                }, []) ?? [],
            })
        },
        {
            text: "Service Bus",
            field: row => row.serviceBus,
            sortable: true,
            headerClass: "column-10",
            filter: selectFilter({
                options: data?.map(d => d.serviceBus).reduce((arr: any[], current) => {
                    if (!arr.some(d => d.value === current)) {
                        arr.push({ value: current, label: current });
                    }
                    return arr;
                }, []) ?? [],
            })
        },
        {
            text: "Correlation ID",
            field: row => row.correlationId,
            sortable: true,
            headerClass: "column-25"
        },
        {
            text: "",
            field: row => (
                <div onClick={(e) => e.stopPropagation()}>
                    {permissionCheck(userPermissions.DeleteMessages) && <span onClick={() => deleteMessage(row)}><FontAwesomeIcon icon={faTrashAlt} size="lg" /></span>}
                    {row.correlationId ? <span><a href={`${seqEndpoint}/#/events?filter=CorrelationId%20=%20%22${row.correlationId}%22`} rel="noreferrer" target="_blank" onClick={(e) => e.stopPropagation()}><img src={`${seqEndpoint}/favicon.ico`} alt="Open in Seq" title="Open in Seq" width={24} height={24} /></a></span> : <></>}
                </div>
            ),
            sortable: false,
            headerClass: "column-5",
            cellClass: "action-panel"
        },
    ], [data]);

    return (<Page header={<Page.Header title="Service Bus" actions={[
        <Button variant="secondary" hidden={!permissionCheck(userPermissions.ClearMessages)} onClick={deleteAllMessages}>Delete All</Button>,
        <Button variant="secondary" hidden={!permissionCheck(userPermissions.DeleteMessages)} disabled={selectedDeadLetters?.length === 0} onClick={deleteSelectedMessages}>Delete Selected</Button>,
        <Button variant="primary" hidden={!permissionCheck(userPermissions.CreateMessages)} disabled={selectedDeadLetters?.length === 0} onClick={resubmitSelectedMessages}>Resubmit</Button>,
    ]}><Navigation /></Page.Header>}>
        <Row>
            <Col>
                <ServiceBusDeadLetterDetails deadLetter={selectedDeadLetter} open={showDetails} onChange={() => { setShowDetails(false) }} />
                <Table
                    clickable
                    loading={isLoading}
                    noDataMessage="There are currently no dead letters"
                    skeletonRows={10}
                    pageSize={10}
                    onRowClick={(row) => {
                        setSelectedDeadLetter(row);
                        setShowDetails(true);
                    }}
                    selectable
                    onSelectionChange={(rows) => setSelectedDeadLetters(rows)}
                    sortColumnIndex={1}
                    sortDirection="desc"
                    columns={columns}
                    keySelector={data => `${data.serviceBus}-${data.id}`} // Ensure the ID is unique across Service Bus instances
                    data={data || []} />
            </Col>
        </Row>
    </Page>);
}
