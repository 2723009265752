import { AppDisplayName, getBaseUrl } from ".";
import { ClientConfig, OAuthConfig } from "../models";

const ApiVersionKey = "APIVersion";

export const populateConfiguration = async (): Promise<void> => {
    var baseUrl = getBaseUrl();

    try {
        const response = await fetch(`${baseUrl}/api/client-config`);

        if (response.status === 200 && response.body) {
            const result = (await response.json()) as ClientConfig;
            sessionStorage.setItem("ClientConfig", JSON.stringify(result));
            window.localStorage.setItem(ApiVersionKey, response.headers.get("x-api-version") || "0.0.0.0");
        }
    } catch (response) {
        throw new Error(
            `${AppDisplayName} is currently unavailable. Please try again later or contact the <a href="mailto:itservicedesk@laingorourke.com.au?subject=${AppDisplayName} Unavailable">Service Desk</a>.`
        );
    }

    return Promise.resolve();
};

export const getConfiguration = (): ClientConfig => JSON.parse(sessionStorage.getItem("ClientConfig")!) as ClientConfig;

export const getCurrentVersion = (): string => {
    let currentVersion = window.localStorage.getItem(ApiVersionKey);
    return currentVersion ? currentVersion : "";
}

export const getAuthConfiguration = (): OAuthConfig => getConfiguration()?.auth;
