import { HealthEntry } from "../../models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classForHealth, iconForStatus } from "../../helpers";


export const HealthReportComponent : React.FC<HealthReportComponentProps> = ({name, check}) => (
<p><FontAwesomeIcon icon={iconForStatus(check.Status)} className={`status-icon text-${classForHealth(check.Status)}`} size="lg" title={check.Status} /> <strong>{name}</strong> - {check.Description}</p>
);

export interface HealthReportComponentProps {
    name: string;
    check: HealthEntry;
}
