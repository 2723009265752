import { useMutation, UseMutationOptions } from "react-query";
import { useHttpClient } from "@laingorourke/core-web-httpclient";
import { httpClientKey } from "../helpers";
import { AxiosResponse } from "axios";

export const useApiPost = <Response, Variables, Data = null>(path: (variables: Variables) => string, options?: UseMutationOptions<AxiosResponse<Response>, null, [Variables, Data]>) => {

    const httpClient = useHttpClient(httpClientKey);

    return useMutation<AxiosResponse<Response>, null, [Variables, Data]>(async ([variables, data]): Promise<AxiosResponse<Response>> => await httpClient.client.post(path(variables), data), options);
}
