const permissions = {
        ViewAppStatus : "app-status-read",

        ViewAlerts : "alert-read",
        CreateAlerts : "alert-write",
        DeleteAlerts : "alert-delete",

        ReadMessages : "messages-read",
        CreateMessages : "messages-write",
        DeleteMessages : "messages-delete",
        ClearMessages: "clear-messages-delete",

        DevToolsRead : "dev-tools-read",
        DevToolsWrite : "dev-tools-write",
};

export default permissions;
