import { Button } from "react-bootstrap";
import { ServiceBusDeadLetter } from "../../models";
import { Drawer } from "@laingorourke/core-web-components";

export const ServiceBusDeadLetterDetails: React.FC<ServiceBusDeadLetterDetailsProps> = ({ deadLetter, ...props }) => deadLetter ? (
    <Drawer {...props} title={`${deadLetter.topicOrQueue}`} push actions={[<Button onClick={props.onChange}>Close</Button>]} showCloseIcon closeOnClickAway width={700} className="dead-letters-pop-up">
        <>
            <h4>Data</h4>
            <textarea value={deadLetter.data && JSON.stringify(deadLetter.data, undefined, 4)} className="dead-letter-editor" />
        </>
    </Drawer>
) : <></>;

export interface ServiceBusDeadLetterDetailsProps {
    deadLetter?: ServiceBusDeadLetter;
    onChange: () => void;
    open: boolean;
}
