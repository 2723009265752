import { System } from "@laingorourke/core-services-authorisation-web-client";
import { Button, Drawer } from "@laingorourke/core-web-components";

import { Health } from "../../models";
import { HealthReportComponent } from "./HealthReportComponent";

export const HealthReport: React.FC<HealthReportProps> = ({ system: app, appHealth, ...props }) => app ? (
    <Drawer {...props} title={`${app.name} - Health - ${appHealth?.Status ?? "Unknown"}`} push actions={[<Button onClick={props.onChange}>Close</Button>]} showCloseIcon closeOnClickAway width={700} >
        {!appHealth && <h4>Unable to retrieve health status</h4>}
        {appHealth &&
            <>
                <h3>Components</h3>
                {Object.keys(appHealth!.Entries).map((e, index) => <HealthReportComponent name={e} check={appHealth!.Entries[e]} key={index} />)}
            </>
        }
    </Drawer>
) : <></>;

export interface HealthReportProps {
    system?: System;
    appHealth?: Health;
    onChange: () => void;
    open: boolean;
}
