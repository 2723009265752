import { Button, ColumnDefinition, Page, Table, TableChangeEvent, ToastMessageType, useToasts } from "@laingorourke/core-web-components";
import { SortDirection } from "@laingorourke/core-web-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons/faTrashAlt";
import format from "date-fns/format";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import { EventGridDeadLetterDetails } from "./EventGridDeadLetterDetails";
import { getConfiguration, pluralise } from "../../helpers";
import { useUserHasPermission } from "../../helpers/hooks";
import userPermissions from "../../helpers/permissions";
import { EventGridDeadLetter } from "../../models";
import Navigation from "./Navigation";
import { useClearEventGridDeadLetters, useDeleteEventGridDeadLetters, useEventGridDeadLetters } from "../../services/deadletters";
import { EventGridResubmitModal } from "./EventGridResubmitModal";

export const EventGrid: React.FC = () => {

    const seqEndpoint = getConfiguration().seqEndpoint;

    const columns: ColumnDefinition<EventGridDeadLetter>[] = [
        {
            text: "Topic",
            field: row => row.topicName,
            sortable: true,
            key: "topic",
            headerClass: "column-20"
        },
        {
            text: "Subscriber",
            field: row => row.subscription,
            sortable: true,
            key: "subscriber",
            headerClass: "column-25"
        },
        {
            text: "Last Delivery Attempt Time",
            field: row => format(new Date(row.lastDeliveryAttemptTime), "yyyy-MM-dd HH:mm:ss"),
            sortable: true,
            key: "last-delivery-attempt-time",
            headerClass: "column-25"
        },
        {
            text: "Correlation ID",
            field: row => row.correlationId,
            sortable: false,
            headerClass: "column-25"
        },
        {
            text: "",
            field: row => (
                <div onClick={(e) => e.stopPropagation()}>
                    {permissionCheck(userPermissions.DeleteMessages) && <span onClick={() => deleteEvent(row)}><FontAwesomeIcon icon={faTrashAlt} size="lg" /></span>}
                    {row.correlationId ? <a href={`${seqEndpoint}/#/events?filter=CorrelationId%20=%20%22${row.correlationId}%22`} rel="noreferrer" target="_blank" onClick={(e) => e.stopPropagation()}><img src={`${seqEndpoint}/favicon.ico`} alt="Open in Seq" title="Open in Seq" width={24} height={24} /></a> : <></>}
                </div>
            ),
            sortable: false,
            headerClass: "column-5",
            cellClass: "action-panel",
        }
    ]

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sortColumnIndex, setSortColumnIndex] = useState(2); // by date
    const [sortDirection, setSortDirection] = useState<SortDirection>("desc");

    const [showDetails, setShowDetails] = useState(false);
    const [showResubmit, setShowResubmit] = useState(false);
    const [selectedDeadLetter, setSelectedDeadLetter] = useState<EventGridDeadLetter>();

    const [selectedDeadLetters, setSelectedDeadLetters] = useState<EventGridDeadLetter[]>([]);

    const { data, isLoading } = useEventGridDeadLetters(currentPage, pageSize, columns[sortColumnIndex].key!, sortDirection);
    const deleteEventGridDeadLetters = useDeleteEventGridDeadLetters();
    const clearEventGridDeadLetters = useClearEventGridDeadLetters();

    const permissionCheck = useUserHasPermission();
    const toasts = useToasts();

    const deleteEvent = (deadLetter: EventGridDeadLetter) => {
        if (window.confirm("Are you sure you want to delete this dead letter? ")) {
            deleteEventGridDeadLetters.mutate([deadLetter.path], {
                onSuccess() {
                    toasts.newMessage({ title: "Success", body: "The dead letter has been marked for deletion. This may not happen immediately.", type: ToastMessageType.Success });
                },
                onError() {
                    toasts.newMessage({ title: "Error", body: "There was an error deleting the dead letter.", type: ToastMessageType.Error });
                }
            });
        }
    }

    const deleteSelectedEvents = () => {
        if (window.confirm(`Are you sure you want to delete ${pluralise("this", selectedDeadLetters)} dead ${pluralise("letter", selectedDeadLetters)}?`)) {
            deleteEventGridDeadLetters.mutate(selectedDeadLetters.map(s => s.path), {
                onSuccess(response) {
                    if (response.status === 207)
                    {
                        toasts.newMessage({ title: "Warning", body: `Some dead letters have been marked for deletion, but others failed.`, type: ToastMessageType.Warning });
                        return;
                    }
                    toasts.newMessage({ title: "Success", body: `The dead ${pluralise("letter", selectedDeadLetters)} ${pluralise("has", selectedDeadLetters)} been marked for deletion. This may not happen immediately.`, type: ToastMessageType.Success });
                },
                onError() {
                    toasts.newMessage({ title: "Error", body: `There was an error deleting the dead ${pluralise("letter", selectedDeadLetters)}.`, type: ToastMessageType.Error });
                }
            });
        }
    }

    const deleteAllEvents = () => {
        if (window.confirm("Are you sure you want to delete ALL dead letters? This is irreversible!")) {
            clearEventGridDeadLetters.mutate(null);
        }
    }

    const onTableChange = (e: TableChangeEvent) => {
        setCurrentPage(e.page);
        setPageSize(e.pageSize);
        setSortColumnIndex(e.sortColumnIndex);
        setSortDirection(e.sortDirection);
    }

    return (<Page header={<Page.Header title="Event Grid" actions={[
        <Button variant="secondary" hidden={!permissionCheck(userPermissions.ClearMessages)} onClick={deleteAllEvents}>Delete All</Button>,
        <Button variant="secondary" hidden={!permissionCheck(userPermissions.DeleteMessages)} disabled={selectedDeadLetters?.length === 0} onClick={deleteSelectedEvents}>Delete Selected</Button>,
        <Button variant="primary" hidden={!permissionCheck(userPermissions.CreateMessages)} disabled={selectedDeadLetters?.length === 0} onClick={() => setShowResubmit(true)}>Resubmit</Button>,
    ]}><Navigation /></Page.Header>}>
        <Row>
            <Col>
                <EventGridDeadLetterDetails deadLetter={selectedDeadLetter} open={showDetails} onChange={() => { setShowDetails(false) }} />
                <EventGridResubmitModal paths={selectedDeadLetters.map(d => d.path)} show={showResubmit} onClose={() => setShowResubmit(false)} />
                <Table
                    clickable
                    loading={isLoading}
                    noDataMessage="There are currently no dead letters"
                    skeletonRows={4}
                    onRowClick={(row) => {
                        setSelectedDeadLetter(row);
                        setShowDetails(true);
                    }}
                    onTableChange={onTableChange}
                    remote
                    pageSize={pageSize}
                    currentPage={currentPage}
                    selectable
                    onSelectionChange={(rows) => setSelectedDeadLetters(rows)}
                    sortColumnIndex={sortColumnIndex}
                    sortDirection={sortDirection}
                    totalRows={(data && data.totalSize) || undefined}
                    columns={columns}
                    keySelector={data => data.path} // Dead letter IDs are not necessarily unique
                    data={(data && data.data) || []}></Table>
            </Col>
        </Row>
    </Page>);
}
