import "./GetToken.scss";

import { useAuth0 } from "@auth0/auth0-react";
import { Button, Page, Select, ToastMessageType, useToasts } from "@laingorourke/core-web-components";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Navigation from "./DevToolsNavigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons/faCopy";
import { OptionTypeBase } from "@laingorourke/core-web-components/dist/Form/SelectProps";
import { sortResourceServers, useResourceServers } from "../../services";

import { ValueOption } from "./ValueOption";

export const GetToken = () => {

    const { getAccessTokenWithPopup } = useAuth0();

    const [audience, setAudience] = useState<string>();
    const [token, setToken] = useState<string>();

    const generate = () => {
        getAccessTokenWithPopup({
            audience: audience
        }).then(token => setToken(token));
    }

    const resourceServers = useResourceServers();

    const toasts = useToasts();

    const copyToClipboard = () => {
        token && navigator.clipboard.writeText(token);
        toasts.newMessage({
            title: "Copied to clipboard",
            type: ToastMessageType.Information
        });
    }

    return (
        <Page className="get-token" header={<Page.Header title="Get Token"><Navigation /></Page.Header>}>
            <Row>
                <Col md={6}>
                    <Form.Group>
                        <Select label="Destination API..." options={resourceServers.data?.sort(sortResourceServers).map<OptionTypeBase>(r => ( { value: r.identifier, label: r.name }) )} value={audience} onChange={(e) => setAudience(e as string)}
                            components={{
                                Option: ValueOption
                            }} />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group>
                        <Button variant="primary" onClick={generate}>Generate</Button>
                    </Form.Group>
                </Col>
            </Row>
            {token &&
                <>
                    <h2>Access Token <FontAwesomeIcon icon={faCopy} className="clickable" onClick={copyToClipboard} title="Copy to clipboard" /></h2>
                    <Page.Section className="token">
                        {token}
                    </Page.Section>
                </>
            }
        </Page>
    );
}
