import { System } from "@laingorourke/core-services-authorisation-web-client";
import { useQuery } from "react-query";

import { Health } from "../models";

export const useHealthCheck = (system: System) => {


    const healthUrl = system.healthCheckUrl;

    return useQuery([system.id, "health"], async () => {

        if (!healthUrl) {
            return { Status: "Unsupported", Entries: {} } as Health;
        }

        const response = await fetch(healthUrl);

        return await response.json();

    }, { staleTime: 30000 });
}

export const useMetadata = (system: System) => {

    const metadataUrl = system.metadataUrl;

    return useQuery([system.id, "metadata"], async () => {

        if (!metadataUrl) {
            return { productVersion: "Unknown"} as any;
        }

        const response = await fetch(metadataUrl);

        return await response.json();

    }, { staleTime: 30000 });
}

