import { OptionTypeBase } from "@laingorourke/core-web-components/dist/Form/SelectProps";
import { OptionProps } from "react-select";


export const ValueOption: React.FC<OptionProps<any, false, OptionTypeBase>> = (args: OptionProps<OptionTypeBase>) => {
    const { innerProps, isDisabled, label, data } = args;
    return !isDisabled ? (
        <div className="option" {...innerProps}><strong>{label}</strong> ({data.value})</div>
    ) : null;
};
