import { Auth0Provider } from "@auth0/auth0-react";
import { AlertsProvider } from "@laingorourke/core-web-components-alerts";
import { configureApiCheck, HttpClientProvider } from "@laingorourke/core-web-httpclient";
import { MyTasksProvider } from "@laingorourke/core-web-mytasksreactsdk";
import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query"

import App from "./App";
import { populateConfiguration, getConfiguration, httpClientKey, logo } from "./helpers";
import { ErrorPage } from "./pages";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<img
    className="launch-logo"
    alt="Ground Control Logo"
    src={logo}
/>,
);

populateConfiguration().then(() => {
    const config = getConfiguration();
    const auth0Config = config?.auth;
    const myTasksConfig = config?.myTasksConfig;
    const notificationServiceConfig = config?.notificationsService;

    const queryClient = new QueryClient();

    root.render(
        <React.StrictMode>
            <Auth0Provider audience={auth0Config.audience} clientId={auth0Config.clientId} domain={auth0Config.domain} redirectUri={window.location.origin}>
                <HttpClientProvider resources={[{ key: httpClientKey, baseUrl: "/api", configure: configureApiCheck, audience: auth0Config.audience }]}>
                    <AlertsProvider baseUrl={notificationServiceConfig?.baseUrl} audience={notificationServiceConfig?.audience}>
                        <MyTasksProvider baseUrl={`${myTasksConfig?.baseUrl!}/api`} audience={myTasksConfig?.audience}>
                            <QueryClientProvider client={queryClient}>
                                <App />
                            </QueryClientProvider>
                        </MyTasksProvider>
                    </AlertsProvider>
                </HttpClientProvider>
            </Auth0Provider>
        </React.StrictMode>
    );
}).catch((error: Error) => {
    root.render(<ErrorPage message={error.message} />);
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
