import { faCircleCheck } from "@fortawesome/free-solid-svg-icons/faCircleCheck";
import { faOctagonXmark } from "@fortawesome/pro-solid-svg-icons/faOctagonXmark";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";

import { HealthStatus } from "../models";

export const classForHealth = (status?: HealthStatus): string => {
    switch (status) {
        case "Healthy": return "success";
        case "Degraded": return "warning";
        case "Unhealthy": return "danger";
        case "Unsupported": return "secondary";
        default: return "danger";
    }
}

export const iconForStatus = (status?: HealthStatus) => {
    switch (status) {
        case "Healthy": return faCircleCheck;
        case "Degraded": return faExclamationTriangle;
        case "Unsupported": return faQuestionCircle;
        default: return faOctagonXmark;
    }
}

export const titleForStatus = (status?:HealthStatus) => {
    switch(status) {
        case "Unsupported": return "Health checks not supported";
        default: return "View health report";
    }
}
