import { Routes } from "@laingorourke/core-web-types";
import { notFoundRoute } from "@laingorourke/core-web-components";
import { Redirect } from "react-router-dom";
import { Convert, EventGrid, GetToken, GetM2MToken, SendEvent, Wallboard } from "./pages";
import { ServiceBus } from "./pages/deadLetters/ServiceBus";

const routes: Routes = {
    home: {
        path: "/",
        exact: true,
        component: <Redirect to="/systems" />
    },
    apps: {
        path: "/systems",
        title: "Systems",
        exact: true,
        component: <Wallboard />
    },
    deadLetters: {
        path: "/deadletters",
        title: "Dead Letters",
        exact: true,
        component: <Redirect to="/deadletters/event-grid" />,
    },
    eventGrid: {
        path: "/deadletters/event-grid",
        title: "Event Grid",
        component: <EventGrid />
    },
    serviceBus: {
        path: "/deadletters/service-bus",
        title: "Service Bus",
        component: <ServiceBus />
    },
    devTools: {
        path: "/devtools",
        exact: true,
        title: "Dev Tools",
        component: <Redirect to="/devtools/get-token" />,
    },
    getToken: {
        path: "/devtools/get-token",
        title: "Get Token",
        component: <GetToken />
    },
    getM2MToken: {
        path: "/devtools/get-m2m-token",
        title: "Get M2M Token",
        component: <GetM2MToken />
    },
    sendEvent: {
        path: "/devtools/send-event",
        title: "Send Event",
        component: <SendEvent />
    },
    convert: {
        path: "/devtools/convert",
        title: "Convert",
        component: <Convert />
    },
    notFound: notFoundRoute,
}

export default routes;
