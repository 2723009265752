import { EventData } from "../models/EventData";
import { useApiPost } from "./useApiPost";


export const useSendEvent = () => {

    const { mutate, ...rest } = useApiPost<null, null, EventData>(() => `events/send`);

    const send = (eventData: EventData) => {
        mutate([null, eventData]);
    };

    return { send, ...rest };
}
