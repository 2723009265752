import { Button, Form, Input, ToastMessageType, useForm, useToasts } from "@laingorourke/core-web-components";
import { Modal } from "react-bootstrap";
import { pluralise } from "../../helpers";
import { EventGridResubmitRequest } from "../../models";
import { useResubmitEventGrid } from "../../services/deadletters";

export const EventGridResubmitModal: React.FC<ResubmitModalProps> = ({paths, ...props}) => {

    const resubmit = useResubmitEventGrid();
    const toasts = useToasts();

    const form = useForm<EventGridResubmitRequest>({
        initialValues: {
            functionUrl: "",
            functionKey: "",
            paths: paths,
        },
        onSubmit: async (values) => {
            await resubmit.mutateAsync([null, { ...values, paths: paths }], {
                onSuccess(response) {
                    if (response.status === 207)
                    {
                        toasts.newMessage({ title: "Warning", body: `Some dead letters have been successfully submitted and deleted, but others failed.`, type: ToastMessageType.Warning });
                        return;
                    }
                    toasts.newMessage({ title: "Success", body: `The dead ${pluralise("letter", paths)} ${pluralise("has", paths)} been resubmitted and deleted.`, type: ToastMessageType.Success });
                },
                onError() {
                    toasts.newMessage({ title: "Error", body: `There was an error resubmitting the dead ${pluralise("letter", paths)}.`, type: ToastMessageType.Error });
                }
            });
            props.onClose();
        }
    });

    return (
        <Modal show={props.show} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Resubmit Dead {pluralise("Letter", paths)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form form={form}>
                    <Form.Row>
                        <Form.Field name="functionUrl" label="Function URL">
                            <Input />
                        </Form.Field>
                    </Form.Row>
                    <Form.Row>
                        <Form.Field name="functionKey" label="Function Key">
                            <Input type="password" />
                        </Form.Field>
                    </Form.Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={props.onClose}>Cancel</Button>
                <Button variant="primary" onClick={form.submitForm}>Resubmit</Button>
            </Modal.Footer>
        </Modal>
    )
}

export interface ResubmitModalProps {
    show: boolean;
    paths: string[];
    onClose: () => void;
}
