import { Button, Form, Input, Page, TextArea } from "@laingorourke/core-web-components"
import { EventData } from "../../models/EventData";
import { useSendEvent } from "../../services/events";
import Navigation from "./DevToolsNavigation";

export const SendEvent = () => {

    const sendEvent = useSendEvent();

    const initialValues: EventData = {
        url: "https://.australiaeast-1.eventgrid.azure.net/api/events"
    };

const submit = (values: EventData) => {

    const objectifiedValues = {
        ...values,
        data: JSON.parse(values.data as string)
    };

    sendEvent.send(objectifiedValues);
}

    return (
        <Page title="Send Event" header={<Page.Header title="Send Event"><Navigation /></Page.Header>}>
            <Page.Section>
                <Form<EventData> initialValues={initialValues} onSubmit={submit}>
                    <Form.Row>
                        <Form.Field name="url" label="Topic URL">
                            <Input />
                        </Form.Field>
                    </Form.Row>
                    <Form.Row>
                        <Form.Field name="key" label="Key">
                            <Input type="password" />
                        </Form.Field>
                    </Form.Row>
                    <Form.Row>
                        <Form.Field name="eventType" label="Event Type">
                            <Input />
                        </Form.Field>
                    </Form.Row>
                    <Form.Row>
                        <Form.Field name="subject" label="Subject">
                            <Input />
                        </Form.Field>
                    </Form.Row>
                    <Form.Row>
                        <Form.Field name="data" label="Data (as JSON)">
                            <TextArea />
                        </Form.Field>
                    </Form.Row>
                    <Form.Row>
                        <Button variant="primary" type="submit">Send</Button>
                    </Form.Row>
                </Form>
            </Page.Section>
        </Page>
    )
}
