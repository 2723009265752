export const pluralise = (word: string, items: any[]) => {
    if (!items || items.length < 2) return word;

    switch (word) {
        case "this":
            return items.length > 1 ? "these" : word;
        case "has":
            return items.length > 1 ? "have" : word;
        case "is":
            return items.length > 1 ? "are" : word;
        default:
            if (items.length < 2) return word;
            if (word.endsWith("y")) return `${word.substring(word.length-1)}ies`;
            return `${word}s`;
    }
}
